<template>
  <v-app id="app">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  created(){
    this.$store.dispatch("loadAmplify");
  }
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
  ::-webkit-scrollbar {
    /* width: 0px;
    border-radius: 0px !important; */
    display: none;
  }
  .soloFondo {
    background-color: #101522 !important;
  }
  .fondo {
  background-color: #101522;
  height: 700px;
}
p{
  color: white;
}
.h1R{
  color: #D125B1;
  font-weight: bold;
  font-size: 45px;
  text-justify: end;
}
.span{
  color: #eb4411;
  font-weight: bold;
  font-size: 15px;
  text-justify: end;
}
h1{
  color: #000000;
  font-weight: bold;
  font-size: 30px;
  text-justify: end;
   /* -webkit-text-stroke: 1px white; */
}
h3{
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-justify: end;
}
.v-dialog{
   overflow-x: hidden;
}
.nombre {
  font-size: 32px;
}

.titulo {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}

.textNotific {
  font-size: 25px;
}
.textNotificAcent {
  font-size: 30px;
  font-weight: 700;
}
</style>
