import {
  solicitud
} from './network/network';
import { resumirConsulta } from '@/components/helpers/Consulta';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getContrato(store, contrato) {
      const invitado = await this._vm.$session.get('invitado');
      const correo = await this._vm.$session.get('correoInvitado');
      let settings = {
        method: 'POST',
        headers: {},
        body: invitado ? {
          correo,
          contrato
        }:{
          contrato
        },
      };
      
      let data = await solicitud(`/smapac/v1/${invitado ? 'searchinvitado':'search'}`, settings, invitado);
      let result = {};
      if (data.statusBusqueda) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: data.calculo.listDetallePagoVista,
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      } else if ((data.informe && data.informe.lista) && (data.informe.lista.isCampaniaSiguienteAnio || data.informe.lista.isCampania)) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: [],
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      }
      return await resumirConsulta(data);;
    },
    async calculate(state, b) {
      const invitado = await this._vm.$session.get('invitado');
      const correo = await this._vm.$session.get('correoInvitado')
      let body = b;
      if(invitado){
        body.correo = correo;
      }
      let settings = {
        method: 'POST',
        headers: {},
        body,
      };
      let result = {};
      let data = await solicitud(`/smapac/v1/${invitado ? 'calculateinvitado':'calculate'}`, settings, invitado);
      if (data.statusBusqueda) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: data.calculo.listDetallePagoVista,
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      } else if ((data.informe && data.informe.lista) && (data.informe.lista.isCampaniaSiguienteAnio || data.informe.lista.isCampania)) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: [],
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      }
      return await resumirConsulta(data);
    },
    async pay(state, body) {
      let settings = {
        method: 'POST',
        headers: {},
        body,
      };
      await solicitud('/smapac/v1/pay', settings);
    },
    async userInfo({getters}) {
      const correo = await this._vm.$session.get('correoInvitado');
      const invitado = await this._vm.$session.get('invitado');
      let settings = {
        method: invitado ? 'POST':'GET',
        headers: {},
        body: invitado ? {correo}:{},
      };
      const url = `/smapac/v1/${invitado?'invitado':'user'}`;
      const data = await solicitud(url, settings, invitado);
      if (data.email) {        
        return data;
      } else {
        return {
          email: '',
          name: '',
          pay: [],
          modulo: [],
        };
      }
    },
    async printPresupuesto(state, body){
      let settings = {
        method: 'POST',
        headers: {},
        body,
      };
      const invitado = await this._vm.$session.get('invitado');
      const data = await solicitud('/smapac/v1/printbugdet', settings, invitado);
      var a = document.createElement("a"); //Create <a>
      a.href = `data:application/pdf;base64,${data}`; //Image Base64 Goes here
      a.download = `Presupuesto ${body.mesFiscal}-${body.anioFiscal}.pdf`; //File name Here
      a.click(); //Downloaded file
      // window.open(`data:application/pdf;base64,${data}`, '_blank');
      return data;
    },
    async printRecibo(state, body){
      let settings = {
        method: 'POST',
        headers: {},
        body,
      };
      const invitado = await this._vm.$session.get('invitado');
      const data = await solicitud('/smapac/v1/printpay', settings, invitado);
      var a = document.createElement("a"); //Create <a>
      a.href = `data:application/pdf;base64,${data}`; //Image Base64 Goes here
      a.download = `Recibo.pdf`; //File name Here
      a.click(); //Downloaded file
      // window.open(`data:application/pdf;base64,${data}`, '_blank');
      return data;
    },
    async conciliar(state, body) {
      let settings = {
        method: 'POST',
        headers: {},
        body,
      };
      const data = await solicitud('/smapac/v1/integration', settings);
      return data.contenido;
    },
    async getRegistros(state) {
      let settings = {
        method: 'GET',
        headers: {},
        body: {},
      };
      const data = await solicitud('/smapac/v1/getconciliacion', settings);
      return data;
    },
    async getRegistroId(state, id) {
      let settings = {
        method: 'GET',
        headers: {},
        body: {},
      };
      const data = await solicitud(`/smapac/v1/getconciliacion/${id}`, settings);
      return data;
    }
  },
}