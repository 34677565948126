import Vue from 'vue';
import VueRouter from 'vue-router';

const DefaultContainer = () => import("@/components/DefaultContainer.vue");
const Dashboard = () => import("@/components/negocio/Dashboard.vue");
const Home = () => import("@/views/Home.vue");
const SingUp = () => import("@/components/negocio/SingUp.vue");
const Conciliacion = () => import("@/components/negocio/Conciliacion.vue");
const Registros = () => import("@/components/negocio/Registros.vue");
const NoFound = () => import("@/views/NoFound.vue");

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/home',
  name: 'default',
  component: DefaultContainer,
  children: [{
      path: 'home',
      name: 'Home',
      component: Home
    },
    {
      path: 'menu',
      name: 'menu',
      component: Dashboard,
      meta: {
        requiresAuth: true,
      }
    },
    {
      path: 'sign-up',
      name: 'singup',
      component: SingUp
    },
    {
      path: 'conciliacion',
      name: 'conciliacion',
      component: Conciliacion,
      meta: {
        requiresAuth: true,
        integracion: true,

      }
    },
    {
      path: 'registros',
      name: 'registros',
      component: Registros,
      meta: {
        requiresAuth: true,
        integracion: true,
      }
    },
  ]
},
{
  path: '404',
  name: 'NoFound',
  component: NoFound,
},
{
  path: "*",
  redirect: '/NoFound',
},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const isLogged = router.app.$session.exists() ? !!router.app.$session.get('isLogged') : false; // store.getters.getInfo;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requireInt = to.matched.some(record => record.meta.integracion);
  if (!requiresAuth) {
    next();
  } else if (isLogged && !requireInt) {
    next();
  }else if(isLogged && requireInt) {
    const integracion = router.app.$session.exists() ? !!router.app.$session.get('integracion') : false;
    if (integracion) {
        next();
      } else {
        next({
          name: "menu"
        });
      }
  } else {
      next({
        name: "Home"
      });
  }
});

export default router