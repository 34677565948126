import Vue from 'vue';
import Vuex from 'vuex';
import Amplify, { Auth } from 'aws-amplify';

import consulta from './consulta';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLogged: false,
    integracion: false,
    notificacion: false
  },
  mutations: {
    saveLogged(state, dato) {
      state.isLogged = dato;
    },
    saveIntegracion(state, dato) {
      state.integracion = dato;
    },
    notificar(state) {
      state.notificacion = !state.notificacion;
    }
  },
  getters: {
    getInfo(store) {
      // const logged = this._vm.$session.get('isLogged')
      return store.isLogged;
      // return store._vm.$session.get('isLogged');
    },
    getIntegracion(store) {
      // return store._vm.$session.get('integracion');
      return store.integracion;
    },
    getNotificacion(store) {
      return store.notificacion;
    }
  },
  actions: {
    async loadAmplify({commit}){
      if(this._vm.$session.exists()){
        if(!this._vm.$session.get('isLogged')){
          this._vm.$session.destroy();
          this._vm.$session.start();
          this._vm.$session.set('isLogged', false);
        }
      } else {
        this._vm.$session.start();
      }
      
      try {
        const invitado = await this._vm.$session.get('invitado');
        if(!invitado){
          await Auth.currentSession();
        }
        this._vm.$session.set('isLogged', true);
        await commit('saveLogged', true);
        return;
      } catch (error) {
        console.error('Load Amplify: ', error)
        await commit('saveLogged', false);
      }
      const auth = {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID, //'us-east-1:da54f2a6-d28f-46b2-90c6-156ed51ddb05',
        // REQUIRED - Amazon Cognito Region
        region: process.env.VUE_APP_REGION,//'us-east-1',
        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: process.env.VUE_APP_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.VUE_APP_USER_POOL_ID,//'us-east-1_yrGVMu0Ry',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.VUE_APP_CLIENT_ID,

        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: process.env.VUE_APP_COGNITO_DOMAIN,  //'smapac-pay.auth.us-east-1.amazoncognito.com',
          redirectSignIn: process.env.VUE_APP_REDIRECT_SIGNIN,//'http://localhost:3000/sign-up',
          redirectSignOut: process.env.VUE_APP_REDIRECT_SIGNOUT, //'http://localhost:3000/',
          responseType: 'token' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
      }
      Amplify.configure({
        Auth: auth,
      });
    },
    async validarSession({
      commit
    }){
      let result = false;
      try {
        let invitado = await this._vm.$session.get('invitado');
      if(!invitado) {
        await Auth.currentSession();
      }
        result = true;
      } catch (error) {
        console.error(error);
      }
      commit('saveLogged', result);
      this._vm.$session.set('isLogged', result);
      if(result){
       const inte = !!this._vm.$session.get('integracion');
       try {
         commit('saveIntegracion', inte);
       } catch (error) {
         console.error(error);
       }
      }
      return result;
     
    },
  },
  modules: {
    consulta
  }
})
