import {
  Auth,
  API
} from 'aws-amplify';

export async function solicitud(url, settings, invitado=false) {
  let session;
  try {
    if(!invitado) {
      const connection = await Auth.currentSession();
      session = connection;
    }
  } catch (error) {
    console.error(error);
    await Auth.signOut();
    // await this.validar();
    await this.$store.dispatch("validarSession");
    this.$router.push({
      name: "home"
    }).catch(()=>{});
    return {};
  }
  API.configure({
    API: {
      endpoints: [{
        name: 'MyAPIGatewayAPI',
        endpoint: `${process.env.VUE_APP_API}`,
        custom_header: () => {
          let header = {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
          if(!invitado) {
            header['Authorization'] = `Bearer ${session.idToken.jwtToken}`
          }
          return header;
        },
      }, ],
    },
  });
  let config = settings;
  let result = {};
  try {
    switch (settings.method.toUpperCase()) {
      case 'POST':
        result = await API.post('MyAPIGatewayAPI', url, config);
        break;
      case 'PUT':
        result = await API.put('MyAPIGatewayAPI', url, config);
        break;
      case 'GET':
        result = await API.get('MyAPIGatewayAPI', url, config);
        break;
      case 'DELETE':
        result = await API.delete('MyAPIGatewayAPI', url, config);
        break
      default:
        result = {};
        break;
    }
    return result;
  } catch (error) {
    console.error(error);
    return result;
  }
}