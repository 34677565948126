export async function resumirConsulta(data) {
    let result = {};
    if (data.statusBusqueda) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: data.calculo.listDetallePagoVista,
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      } else if ((data.informe && data.informe.lista) && (data.informe.lista.isCampaniaSiguienteAnio || data.informe.lista.isCampania)) {
        result = {
          id: data.calculo ? data.calculo.idcontrato : null,
          statusBusqueda: data.statusBusqueda,
          mensaje: data.mensaje,
          informe: [],
          total: JSON.stringify(data.total),
          contrato: data.contrato,
          ivaTotal: data.totalIva,
          subtotal: data.subtotal,
          referenciabbva: data.referenciabbva,
          signature: data.signature,
          anio: data.informe.lista.anioActual,
          mes: data.informe.lista.peridoActual,
          nombre: data.nombre,
          direccion: data.direccion,
          rfc: data.rfc,
          activarRecalcular: true,
          anioAnterior: data.informe.lista.ultimoAfiscalPagado,
          mesAnterior: data.informe.lista.ultimoPeriodoPagado,
        }
      }
    return result;
}